import '../App.css';
import { useNavigate } from 'react-router-dom';

export function WebLink()
{
    const navigate = useNavigate();

    const changeWeb = (path) =>
    {
        // console.log('deez')
        navigate(path)
    };

    // const stuff = (path) =>
    // {
    //     console.log(path)
    // };

    return changeWeb;
}
