import {BrowserRouter as Router, Routes, Route, Link, Switch, BrowserRouter} from 'react-router-dom';
import Home from './pages/Home.js';
import AppointmentMenu from './pages/AppointmentMenu.js';
import AppointmentPage from './pages/AppointmentPage.js';

function App() {
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path="/home" element={<Home />} />
                <Route path="/appointment" element={<AppointmentMenu />} />
                <Route path="/appointment/appointment-page" element={<AppointmentPage />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
