import '../App.css';
import 'animate.css';
import {WebLink} from '../components/ChangeWeb.js';
import {MenuButton} from '../components/Menu-Button.js';

export default function Home(){
    const changeWeb = WebLink(); //this is setting changeWeb to be the result of WebLink which is returning changeWeb

    return (
        <div className="App">
            <header className="Spa-header">
                <div>
                    <MenuButton
                        style={{
                            display:'inline-block',
                            background: 'transparent',
                            padding:'0px',
                            outline:'none',
                        }}
                        onPress={() => changeWeb('/home')}
                    >
                        <img className= "Rose" src={ ('/images/rose.png')} alt="rose-logo" />
                    </MenuButton>
                </div>
                <div>
                    <h1>
                        Rose Garden Spa
                    </h1>
                </div>
            </header>
            <body>
                <div className= "MainBody">
                    <img className="SpaImage" src={('/images/spaimg1.jpeg')} alt="" />
                    <div>
                        <h1 style={{marginLeft: '0.5em'}} class="animate__animated animate__fadeIn animate__slower"> Where the world fades away, and your well-being takes center stage </h1>
                    </div>
                    <div class="animate__animated animate__bounceInLeft animate__delay-1s animate__slower">
                        <MenuButton onPress={() => changeWeb('/appointment')}
                        > Request an Appointment </MenuButton>
                    </div>
                </div>
            </body>
        </div>
    );
}