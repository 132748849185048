import '../App.css';
import React, {useState} from 'react';
import {WebLink} from '../components/ChangeWeb.js';
import SelectMenu from '../components/Select-Menu.js';
import {MenuButton} from '../components/Menu-Button.js';
import 'animate.css'


export default function AppointmentMenu ()
{
    const [hoveredSelectMenu, setHoveredSelectMenu] = useState(null);
    const changeWeb = WebLink(); //this is setting changeWeb to be the result of WebLink which is returning changeWeb
    const isMobile = window.innerWidth <= 420;
    const handleSelectMenuHover = (menuId) =>
    {
        console.log(menuId)
        setHoveredSelectMenu(menuId)
    }

    return(
        <div className="App">
            <header className="Spa-header">
                <div>
                    <MenuButton
                        style={{
                            display: 'inline-block',
                            background: 'transparent',
                            padding: '0px',
                            outline: 'none',
                        }}
                        onPress={() => changeWeb('/home')}
                    >
                        <img className="Rose" src={('/images/rose.png')} alt="rose-logo"/>
                    </MenuButton>
                </div>
                <div>
                    <h1>
                        Rose Garden Spa
                    </h1>
                </div>
            </header>
            <body>
                <div className="AppointmentBody">
                    <div className="BodyTextDiv">
                        <h1> START BOOKING</h1>
                        <h1> What can we do for you? </h1>
                    </div>
                    <div className="BodyStyling1"/>
                </div>

                <div style={{display: 'flex'}}>
            <div
                onMouseEnter = {() => handleSelectMenuHover(1)}
                onMouseLeave = {() => handleSelectMenuHover(0)}
                style={{
                    opacity: hoveredSelectMenu === 2 ? 0 : 1,
                    width: '0',
                }}
            >
                <SelectMenu
                buttonText1="30 mins ($45)"
                buttonText2="60 mins ($60)"
                buttonText3="90 mins ($90)"
                imageUrl='/images/bodymassage.png'
                bodyDescription="Deep tissue body massage is a profoundly therapeutic and rejuvenating experience. As the session
                    draws to a close, you'll find yourself in a state of blissful surrender, your body and mind
                    harmonized, stress melted away, and a renewed sense of vitality and peace coursing through your
                    very being. A deep tissue massage is not just a pampering treat; it's a transformative journey
                    that leaves you feeling grounded, rejuvenated, and deeply soothed."
                typeOfMassage="Body Massage"
                transformImg='translate(40px,-20px)'
                >
                </SelectMenu>
            </div>
            <div
                onMouseEnter= {() => handleSelectMenuHover(2)}
                onMouseLeave= {() => handleSelectMenuHover(0)}
                style={{
                    opacity: hoveredSelectMenu === 1 ? 0 : 1,
                    width: '0',
                }}
            >
                <SelectMenu
                    buttonText1="30 mins ($45)"
                    buttonText2="60 mins ($60)"
                    imageUrl='/images/combomassage.jpeg'
                    bodyDescription="Get ready for a fantastic experience – our 30-minute body and 30-minute foot combo massage is like a double dose of relaxation.
                     First, we'll ease your body's stress and tension with a soothing massage, helping you unwind and feel great.
                     This combo massage is like a one-two punch of relaxation.
                    Your whole body will feel relaxed, your mind will be at peace, and you'll leave with a bounce in your step.
                    It's a perfect way to take a break and treat yourself."
                    typeOfMassage="Combo Massage"
                    massageImageContainer={{
                        left: isMobile ? '12vw' : '70vw',
                        top: isMobile ? '-60vh' : '-109vh',
                    }}
                    transformImg='translate(-90px,-20px)'
                    bodyDescriptionPosition={{
                        top: isMobile ? '-90vh' : '-35vh',
                        left: isMobile ? '-26vw' : '-900px',
                    }}
                >
                </SelectMenu>
            </div>
                </div>
        </body>
    </div>
    );
}

