import '../App.css';
import {MenuButton} from "../components/Menu-Button";
import {WebLink} from "../components/ChangeWeb";
import {useForm} from "react-hook-form";
import React from 'react';


export default function AppointmentPage()
{
    const changeWeb = WebLink();
    const {register, handleSubmit, formState: {errors}} = useForm();
    

    const onSubmit = (data) => {
        // console.log(data.message)
        alert("Appointment Sent!")
        sendEmail(data);
    }

    const baseUrl = "https://rosegardenspa-a81a956e404c.herokuapp.com";

    const sendEmail = async (data) => {
        let dataSend = {
          email: 'rosegardenspa93422@gmail.com',
          subject: "Appointment",
          message: "First Name: "+data.firstName+"\n"+"Last Name: "+data.lastName+"\n"+"Email: "+data.email+"\n"+
              "Massage Type: "+data.massage+"\n"+"Message: "+data.message
        };
    
        const res = await fetch(`${baseUrl}/email/sendEmail`, {
          method: "POST",
            mode: "cors",
          body: JSON.stringify(dataSend),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          // HANDLING ERRORS
          .then((res) => {
            console.log(res);
            if (res.status > 199 && res.status < 300) {
              alert("Send Successfully !");
            }
          });
      };

    return(
        <div className="App">
            <header className="Spa-header">
                <div>
                    <MenuButton
                        style={{
                            display:'inline-block',
                            background: 'transparent',
                            padding:'0px',
                            outline:'none',
                        }}
                        onPress={() => changeWeb('/home')}
                    >
                        <img className= "Rose" src={ ('/images/rose.png')} alt="rose-logo" />
                    </MenuButton>
                </div>
                <div>
                    <h1 style={{color:'azure'}}>
                        Rose Garden Spa
                    </h1>
                </div>
                <div className="AppointmentTitle">
                    <h1
                    style={{color:'azure'}}
                    >
                        APPOINTMENTS AVAIALABLE
                    </h1>
                </div>
            </header>
            <body>
                <div className="FormBody">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input {...register("firstName", {required: 'Please enter your first name'})}
                               placeholder="First Name"/>
                        <p>{errors.firstName?.message}</p>
                        <input {...register("lastName", {required: 'Please enter your last name'})}
                               placeholder="Last Name"/>
                        <p>{errors.lastName?.message}</p>
                        <input {...register("email", {required: 'Please enter your email'})}
                               placeholder="Email"/>
                        <p>{errors.email?.message}</p>
                        <select {...register("massage")}>
                            <option value="body massage">body massage</option>
                            <option value="combo massage">combo massage</option>
                            <option value="other">other</option>
                        </select>
                        <textarea {...register("message")} placeholder="Please enter a time and any additional requests. e.g('I would like a 30 minute body massage at 4:30 on Oct 24)"/>
                        <input type="submit"/>
                    </form>
                </div>
            </body>
        </div>
    );
}