import '../App.css';
import React from 'react'
import {useState} from 'react';
import {WebLink} from '../components/ChangeWeb.js';
import {MenuButton} from '../components/Menu-Button.js';
import 'animate.css'

export default function SelectMenu (props) {
    const [isHovered, setIsHovered] = useState(false);
    const changeWeb = WebLink(); //this is setting changeWeb to be the result of WebLink which is returning changeWeb

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className="App">
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="MassageImageContainer"
                style={{...props.massageImageContainer}}
            >
                <h1
                    style={{
                        position: 'absolute',
                        top: '-60px',
                        color: 'lemonchiffon',
                        fontSize: '30px',
                        textAlign: 'center',
                        textDecoration: 'underline',
                        zIndex: '1',
                        opacity: isHovered ? 0 : 1
                    }}
                > SELECT </h1>
                {isHovered ?
                    (<img className="BodyMassageImage"
                          style={{
                              transform: isHovered ? props.transformImg : 'none',
                              border: '1px solid black',
                              animationName: 'fadeIn',
                              animationDuration: '3s',
                          }}
                          src={('/images/spaimg1.jpeg')} alt="massage"
                    />)
                    :
                    (<img className="BodyMassageImage"
                          src={props.imageUrl} alt="massage"
                    />)
                }

                <ul className={isHovered ? "UnhiddenButton" : "HiddenButton"}
                    style={{
                        position: 'absolute',
                        zIndex: 1,
                        width: '100px',
                        top: '40px',
                        left: '20px',
                        margin: '3px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <MenuButton
                        style={{marginBottom: '10px',  scale: props.buttonText1 ? 1 : 0}}
                        onPress={() => changeWeb('./appointment-page')}
                    > {props.buttonText1} </MenuButton>
                    <MenuButton
                        style={{marginBottom: '10px',  scale: props.buttonText2 ? 1 : 0}}
                        onPress={() => changeWeb('./appointment-page')}
                    > {props.buttonText2} </MenuButton>
                    <MenuButton
                        style={{
                            marginBottom: '10px',
                            scale: props.buttonText3 ? 1 : 0
                        }}
                        onPress={() => changeWeb('./appointment-page')}
                    > {props.buttonText3} </MenuButton>

                    <h1 className="BodyMassageDescription"
                        style={{...props.bodyDescriptionPosition}}
                    >
                        {props.bodyDescription}
                    </h1>
                </ul>
                <div className={isHovered ? "BMTextHidden" : "BMText"}> {props.typeOfMassage} </div>
            </div>
        </div>
    );
}